import GOBALS from '../GOBALS';

export default class PromotionDetailModel{
    async getPromotionDetailByCode(data){
        return fetch(GOBALS.URL + 'promotionDetail/getPromotionDetailByCode',{
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        }).catch((error) => {
            console.error(error);
        });
    }   
}