import React, { Component } from 'react'
import '../assets/css/banner.css'
import Dots from '../containers/style-dots-banners/StyleDots'
import Carousel from '../containers/carousel/carousel'
import GOBALS from '../GOBALS';

import BannerModel from '../models/BannerModel';

const banner_model = new BannerModel();
export default class Banner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banners: []
        };
    }

    componentDidMount() {
        this._fetchData();
    }

    async _fetchData() {
        const banners = await banner_model.getBannerBy({ banner_page: this.props.page })
        this.setState({
            banners: banners.data.result
        })
    }
    render() {
        const { banners } = this.state
        return (
            <div className="size-banner">
                <Carousel auto loop widgets={[Dots]} >
                    {banners.map((item, no) => (
                        <img
                            key={no}
                            alt={item.banner_id + "banner"}
                            className="size-banner"
                            src={GOBALS.URL_IMG + item.banner_url}
                            width="100%"
                            style={{ objectFit: "cover" }}
                        />
                    ))}
                </Carousel>
            </div>
        )
    }
}
