import React, { Component } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'

import GuitarTypeModel from '../../models/GuitarTypeModel';
import GuitarTypeDescriptionModel from '../../models/GuitarTypeDescriptionModel';

const guitar_type_model = new GuitarTypeModel();
const guitar_type_description_model = new GuitarTypeDescriptionModel();

export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guitar_types: [],
            guitar_type_description_detail: ''
        };
    }
    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const guitar_types = await guitar_type_model.getGuitarTypeBy();
        const guitar_type_description = await guitar_type_description_model.getGuitarTypeDescriptionBy({
            guitar_type_description_language: sessionStorage.getItem('language')
        })
        this.setState({
            guitar_types: guitar_types.data.result,
            guitar_type_description_detail: guitar_type_description.data[0].guitar_type_description_detail
        })
    }
    render() {
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="guitarType"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Guitar Type </div>
                </div>
                <Container>
                    <Row>
                        <Col className="title">
                            FONZO GUITAR
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div
                                className="description"
                                dangerouslySetInnerHTML={{ __html: this.state.guitar_type_description_detail }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '90px' }}>
                        {this.state.guitar_types.map((item, no) => (
                            <Col key={no} lg={'auto'} style={{ marginBottom: '15px' }}>
                                <Link to={`/guitar-type/${item.guitar_type_code}`} style={{ width: '100%' }}>
                                    <Card
                                        className="links card-body size-block"
                                    >
                                        <Card.Img variant="top"
                                            src={GOBALS.URL_IMG + item.guitar_type_img}
                                            height="250px" width="auto" style={{ borderRadius: "5px", objectFit: "cover" }}
                                        />
                                        <Card.Body style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#000' }}>
                                                {item.guitar_type_name}
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        )
    }
}
