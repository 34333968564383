import React from 'react'
import propTypes from 'prop-types'
import '../../assets/css/banner.css'

function Dot(props) {
    return (
        <span
            className={"w3-badge w3-border w3-transparent click-color-hover-icon border-color-icon " + (props.selected ? 'click-color-icon' : '')}
            onClick={props.onClick}
        />
    )
}

export default function Dots(props) {
    if (props.total < 2) {
        return (
            <div className="w3-large flex-container icon-position" />
        )
    } else {
        return (
            <div className="w3-large flex-container icon-position">
                {
                    Array.apply(null, Array(props.total)).map((x, i) => {
                        return <Dot key={i} selected={props.index === i} onClick={() => {props.setFrameHandler(i,1000)}} />
                    })
                }
            </div>
        )
    }

}

Dots.propTypes = {
    index: propTypes.number.isRequired,
    total: propTypes.number.isRequired,
    setFrameHandler: propTypes.func,
}