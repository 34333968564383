import GOBALS from '../GOBALS';

export default class AccessoriesModel {
    async getAccessoriesByCode(data) {
        return fetch(GOBALS.URL + 'accessories/getAccessoriesByCode', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            }).catch((error) => {
                console.error(error);
            });
    }
    async getAccessoriesBy(data) {
        return fetch(GOBALS.URL + 'accessories/getAccessoriesBy', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            }).catch((error) => {
                console.error(error);
            });
    }
}