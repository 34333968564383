import GOBALS from '../GOBALS';

export default class AlbumModel{
    async getAlbumByCode(data){
        return fetch(GOBALS.URL + 'album/getAlbumByCode',{
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        }).catch((error) => {
            console.error(error);
        });
    }   

    async getAlbumBy(data){
        return fetch(GOBALS.URL + 'album/getAlbumBy',{
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        }).catch((error) => {
            console.error(error);
        });
    }   
}