import React, { Component } from 'react'
import GOBALS from '../../GOBALS';
import '../../assets/css/gallery.css'
import '../../assets/css/body.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Banner from '../Banner'
import { Link } from "react-router-dom";
import { Player, BigPlayButton } from 'video-react';
import 'react-bnb-gallery/dist/style.css'
import ReactBnbGallery from 'react-bnb-gallery';

import ImagesModel from '../../models/ImagesModel'
import AlbumModel from '../../models/AlbumModel'

const images_model = new ImagesModel();
const album_model = new AlbumModel();
export default class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            album: [],
            show_thumbnail: false,
            number_image: 0
        };
    }

    componentDidMount() {
        AOS.init({
            duration: 1000
        })
        this._fetchData()
    }

    async _fetchData() {
        const album_code = this.props.match.params.album_code;
        const images = await images_model.getImagesBy({ album_code: album_code });
        const album = await album_model.getAlbumByCode({ album_code: album_code })
        this.setState({
            images: images.data.result,
            album: album.data[0]
        })
        this._galleryActive()
    }

    async _galleryActive() {
        var gallery = document.querySelector('#gallery');
        var getVal = function (elem, style) { return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); };
        var getHeight = function (item) { return item.querySelector('.content').getBoundingClientRect().height; };
        var resizeAll = function () {
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            gallery.querySelectorAll('.gallery-item').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });
            gallery.querySelectorAll('.gallery-item-video').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });
        };
        gallery.querySelectorAll('img').forEach(function (item) {
            item.classList.add('byebye');
            item.addEventListener('load', function () {
                var altura = getVal(gallery, 'grid-auto-rows');
                var gap = getVal(gallery, 'grid-row-gap');
                var gitem = item.parentElement.parentElement;
                gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                item.classList.remove('byebye');
            });
        });

        gallery.querySelectorAll('.gallery-item-video').forEach(function (item) {
            var el = item;
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
        });

        window.onscroll = (e) => {
            if (window.scrollY > 0 && window.scrollY < 500) {
                AOS.refresh();
            }
        };
        window.addEventListener('resize', resizeAll);
    }
    render() {
        const PHOTOS = this.state.images.map((item, no) => {
            if (item.images_type === 'Image') {
                return {
                    photo: GOBALS.URL_IMG + item.images_url,
                    number: no,
                    thumbnail: GOBALS.URL_IMG + item.images_url,
                }
            }
        })
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Gallery"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <Link to="/gallery">
                        <div>Gallery</div>
                    </Link>
                    <div>/</div>
                    <div>Gallery Detail</div>
                </div>
                {this.state.images.length !== 0 ?
                    <ReactBnbGallery
                        show={this.state.show_thumbnail}
                        activePhotoIndex={this.state.number_image}
                        photos={PHOTOS}
                        onClose={() => { this.setState({ show_thumbnail: false }) }}
                    /> : null
                }
                <div className="body-gallery" style={{ marginLeft: '3vw', marginRight: '3vw' }}>
                    <h1 style={{ paddingTop: '2rem', textAlign: 'center', paddingBottom: '1rem' }}>{this.state.album.album_name}</h1>
                    <div className="gallery" id="gallery">
                        {this.state.images.map((item, no) => {
                            if (item.images_type === 'Image') {
                                return (
                                    <div key={`gallery${no}`} className="gallery-item" data-aos="fade-up">
                                        <div className="content">
                                            <img key={no}
                                                src={GOBALS.URL_IMG + item.images_url}
                                                alt=""
                                                onClick={() => this.setState({show_thumbnail: true, number_image: no})}
                                            />
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={`gallery${no}`} className="gallery-item-video" data-aos="fade-up">
                                        <div className="content">
                                            <Player
                                                poster={`${GOBALS.URL_IMG}${item.images_profile_video}`}
                                            >
                                                <BigPlayButton position="center" />
                                                <source src={`${GOBALS.URL_IMG}${item.images_url}`} />
                                            </Player>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
