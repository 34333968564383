import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'

import BrandStoryModel from '../../models/BrandStoryModel';

const brand_story_model = new BrandStoryModel();

export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brand_story: []
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const brand_story = await brand_story_model.getBrandStoryBy({ brand_story_language: sessionStorage.getItem('language') });
        this.setState({
            brand_story: brand_story.data[0]
        })
    }
    render() {
        const { brand_story } = this.state
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="BrandStory"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Brand Story </div>
                </div>
                <Container>
                    <div className="w3-row w3-padding-64" >
                        <div className="w3-col m6 w3-padding-large">
                            {brand_story.brand_story_img !== undefined ?
                                <img src={GOBALS.URL_IMG + brand_story.brand_story_img}
                                    alt="brandStory"
                                    className="w3-image image-about-brand"
                                />
                                : ''
                            }
                        </div>

                        <div className="w3-col m6 w3-padding-large">
                            <h1 className="w3-center" style={{ color: "#e6275c" }}>Brand Story</h1>
                            <div className="w3-large" style={{ lineHeight: "35px" }}>
                                <div dangerouslySetInnerHTML={{ __html: brand_story.brand_story_description }} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
