import React, { Component } from 'react'
import { Container, Table, Col } from 'react-bootstrap';
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'
import { Link } from "react-router-dom";

import CatalogModel from '../../models/CatalogModel';

const catalog_model = new CatalogModel();

export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            catalogs: []
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const catalogs = await catalog_model.getCatalogBy();
        this.setState({
            catalogs: catalogs.data.result
        })
    }
    render() {
        const { catalogs } = this.state
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Home"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Catalog</div>
                </div>
                <Container>
                    <Col className="title-catalog">Catalog</Col>
                    <Table striped bordered hover className="table-file">
                        <thead style={{ backgroundColor: '#fff' }}>
                            <tr>
                                <th>#</th>
                                <th>File Name</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {catalogs.map((item, no) => (
                                <tr key={no} style={{ fontSize: '1.3rem', fontFamily: 'kanit' }}>
                                    <td>{no + 1} </td>
                                    <td>{item.catalog_title} </td>
                                    <td>
                                        <a href={`${GOBALS.URL_IMG}${item.catalog_file}`}>
                                            <button className="btn btn-success"><i className="fa fa-download" aria-hidden="true"></i> Download</button>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
}
