import React, { Component } from 'react'
import { Col, Button, Card, Container } from 'react-bootstrap';
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import "../../../node_modules/video-react/dist/video-react.css";
import '../../assets/css/gallery-home.css'
import Banner from '../Banner'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Player, BigPlayButton, ControlBar, PlayToggle, TimeDivider, CurrentTimeDisplay } from 'video-react';
import 'react-bnb-gallery/dist/style.css'
import ReactBnbGallery from 'react-bnb-gallery';

import SettingModel from '../../models/SettingModel';
import AboutUsModel from '../../models/AboutUsModel';
import GuitarTypeModel from '../../models/GuitarTypeModel';
import GuitarModel from '../../models/GuitarModel';
import AccessoriesModel from '../../models/AccessoriesModel';
import ImagesModel from '../../models/ImagesModel';

const setting_model = new SettingModel();
const about_us_model = new AboutUsModel();
const guitar_type_model = new GuitarTypeModel();
const guitar_model = new GuitarModel();
const accessories_model = new AccessoriesModel();
const images_model = new ImagesModel();
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            about_us: [],
            setting: [],
            guitar_type: [],
            guitar_popular: [],
            accessories_popular: [],
            images: [],
            show_thumbnail: false,
            number_image: 0
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const about_us = await about_us_model.getAboutUsBy({ about_us_language: sessionStorage.getItem('language') });
        const setting = await setting_model.getSettingBy({ address_language: sessionStorage.getItem('language') });
        const guitar_type = await guitar_type_model.getGuitarTypeBy({
            setting_select_menu_guitar_1: setting.data[0].setting_select_menu_guitar_1,
            setting_select_menu_guitar_2: setting.data[0].setting_select_menu_guitar_2,
            setting_select_menu_guitar_3: setting.data[0].setting_select_menu_guitar_3
        });
        var limit_popular_guitar = ''
        var limit_popular_accessories = ''
        var number = 0
        const guitar_popular = await guitar_model.getGuitarBy({
            show_guitar_popular: 'Yes',
            guitar_detail_language: sessionStorage.getItem('language')
        })
        const accessories_popular = await accessories_model.getAccessoriesBy({
            show_accessories_popular: 'Yes',
            accessories_detail_language: sessionStorage.getItem('language')
        })
        if (guitar_popular.data.result.length >= 3) {
            if (accessories_popular.data.result.length >= 3) {
                limit_popular_guitar = 'LIMIT 3'
                limit_popular_accessories = 'LIMIT 3'
            } else if (accessories_popular.data.result.length < 3 && accessories_popular.data.result.length !== 0) {
                number = 6 - accessories_popular.data.result.length
                limit_popular_guitar = 'LIMIT ' + number
                limit_popular_accessories = 'LIMIT ' + (6 - number)
            } else {
                limit_popular_guitar = 'LIMIT ' + 6
            }
        } else {
            if (accessories_popular.data.result.length >= 3) {
                number = 6 - guitar_popular.data.result.length
                limit_popular_guitar = 'LIMIT ' + (6 - number)
                limit_popular_accessories = 'LIMIT ' + number
            }
        }

        const guitar_popular_show = await guitar_model.getGuitarBy({
            show_guitar_popular: 'Yes',
            limit_popular: limit_popular_guitar,
            guitar_detail_language: sessionStorage.getItem('language')
        })
        const accessories_popular_show = await accessories_model.getAccessoriesBy({
            show_accessories_popular: 'Yes',
            limit_popular: limit_popular_accessories,
            accessories_detail_language: sessionStorage.getItem('language')
        })
        const images = await images_model.getImagesBy();
        this.setState({
            about_us: about_us.data[0],
            setting: setting.data[0],
            guitar_type: guitar_type.data.result,
            guitar_popular: guitar_popular_show.data.result,
            images: images.data.result,
            accessories_popular: accessories_popular_show.data.result,
        })

        this._galleryActive()
    }

    async _galleryActive() {
        var gallery = document.querySelector('#gallery-home');
        var getVal = function (elem, style) { return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); };
        var getHeight = function (item) { return item.querySelector('.content-home').getBoundingClientRect().height; };
        var resizeAll = function () {
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            gallery.querySelectorAll('.gallery-item-home').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });

            gallery.querySelectorAll('.gallery-item-video-home').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });
        };
        gallery.querySelectorAll('img').forEach(function (item) {
            item.classList.add('byebye-home');
            item.addEventListener('load', function () {
                var altura = getVal(gallery, 'grid-auto-rows');
                var gap = getVal(gallery, 'grid-row-gap');
                var gitem = item.parentElement.parentElement;
                gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                item.classList.remove('byebye-home');
            });
        });

        gallery.querySelectorAll('.gallery-item-video-home').forEach(function (item) {
            var el = item;
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
        });

        window.addEventListener('resize', resizeAll);
    }

    _goTopPage() {
        window.scrollTo(0, 0);
    }

    render() {
        const { about_us, setting, guitar_type, guitar_popular, accessories_popular, images } = this.state
        const PHOTOS = images.map((item, no) => {
            if (item.images_type === 'Image') {
                return {
                    photo: GOBALS.URL_IMG + item.images_url,
                    number: no,
                    thumbnail: GOBALS.URL_IMG + item.images_url,
                }
            }
        })
        var setting_slide = {
            className: "slider variable-width container-flex-popular",
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Home"
                    />
                </div>
                <div>
                    <Container>
                        <Col className="title-home">Founder</Col>
                        <div className="w3-row container-flex-wrap-reverse" >
                            <div className="w3-col m6 ">
                                <div className="w3-large">
                                    <div className="description-home" dangerouslySetInnerHTML={{ __html: about_us.about_us_description }} />
                                </div>
                                <Link to="/founder" >
                                    <Button className="button-color" variant="danger" size="lg">READ MORE</Button>
                                </Link>
                            </div>
                            <div className="w3-col m6" style={{ marginBottom: '5px' }}>
                                {about_us.about_us_img !== undefined ?
                                    <img src={GOBALS.URL_IMG + about_us.about_us_img}
                                        alt="aboutUs"
                                        className="w3-image image-about-home"
                                    />
                                    : ''
                                }
                            </div>
                        </div>
                    </Container>
                    <Col className="title-home">
                        Featured Products
                    </Col>
                    {guitar_type[0] !== undefined ?
                        <div className="container-flex-menu">
                            <div className="container-menu-home">
                                <Link to={`/guitar-type/${guitar_type[0].guitar_type_code}`} >
                                    <div className="bg-guitar-menu" onClick={() => this._goTopPage()}>
                                        <img alt="popular" src={`${GOBALS.URL_IMG}${guitar_type[0].guitar_type_img}`} className="image-menu menu-padding-1" />
                                        <div className="bg-text-guitar-menu">
                                            <p className="text-guitar-menu">{guitar_type[0].guitar_type_name}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="container-menu-home">
                                <Link to={`/guitar-type/${guitar_type[1].guitar_type_code}`} >
                                    <div className="bg-guitar-menu" onClick={() => this._goTopPage()}>
                                        <img alt="popular" src={`${GOBALS.URL_IMG}${guitar_type[1].guitar_type_img}`} className="image-menu menu-padding-1" />
                                        <div className="bg-text-guitar-menu">
                                            <p className="text-guitar-menu">{guitar_type[1].guitar_type_name}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="container-menu-home">
                                <Link to={`/guitar-type/${guitar_type[2].guitar_type_code}`} >
                                    <div className="bg-guitar-menu" onClick={() => this._goTopPage()}>
                                        <img alt="popular" src={`${GOBALS.URL_IMG}${guitar_type[2].guitar_type_img}`} className="image-menu menu-padding-1" />
                                        <div className="bg-text-guitar-menu">
                                            <p className="text-guitar-menu">{guitar_type[2].guitar_type_name}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="container-menu-home">
                                <Link to="/accessories-type" >
                                    <div className="bg-guitar-menu" onClick={() => this._goTopPage()}>
                                        <img alt="popular" src={`${GOBALS.URL_IMG}${setting.setting_image_menu_accessories}`} className="image-menu" />
                                        <div className="bg-text-guitar-menu">
                                            <p className="text-guitar-menu">Accessories</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        : ''
                    }
                    <Container>
                        <Col className="title-home"> Popular Products </Col>
                        <Slider {...setting_slide}>
                            {guitar_popular.map((item, no) => (
                                <div className="product-list" key={no}>
                                    <a href={`https://www.fonzoguitar.com/guitar-type/${item.guitar_type_code}/${item.guitar_code}`} style={{ width: '100%' }}>
                                        <Card
                                            className="links card-body card-body-size"
                                        >
                                            {item.guitar_img_url !== null ?
                                                < Card.Img variant="top"
                                                    className="img-size-popular"
                                                    src={GOBALS.URL_IMG + item.guitar_img_url}
                                                />
                                                :
                                                < Card.Img variant="top"
                                                    height="250px"
                                                    width="180px"
                                                    style={{ objectFit: 'cover' }}
                                                    src={require('../../assets/img/default-image.jpg')}
                                                />
                                            }
                                            <Card.Body>
                                                <Card.Title style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: '#000', marginTop: '5px' }}>
                                                    {item.guitar_detail_text}
                                                </Card.Title>
                                                <Card.Text style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: 'red' }}>
                                                    ฿{item.guitar_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            ))}
                            {accessories_popular.map((item, no) => (
                                <div className="product-list" key={no}>
                                    <a href={`https://www.fonzoguitar.com/accessories-type/${item.accessories_type_code}/${item.accessories_code}`} style={{ width: '100%' }}>
                                        <Card
                                            className="links card-body card-body-size"
                                        >
                                            {item.accessories_img_url !== null ?
                                                < Card.Img variant="top"
                                                    className="img-size-popular"
                                                    src={GOBALS.URL_IMG + item.accessories_img_url}
                                                />
                                                :
                                                < Card.Img variant="top"
                                                    height="250px"
                                                    width="180px"
                                                    style={{ objectFit: 'cover' }}
                                                    src={require('../../assets/img/default-image.jpg')}
                                                />
                                            }
                                            <Card.Body>
                                                <Card.Title style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: '#000', marginTop: '5px' }}>
                                                    {item.accessories_detail_text}
                                                </Card.Title>
                                                <Card.Text style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: 'red' }}>
                                                    ฿{item.accessories_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </div>
                            ))}
                        </Slider>
                        <div className="container-flex-button-center">
                            <Link to={`/popular/guitar`}>
                                <Button className="button-color-popular" onClick={() => this._goTopPage()} variant="danger" size="lg">MORE PRODUCT</Button>
                            </Link>
                        </div>

                        <Col className="title-home">Gallery</Col>
                        {images.length !== 0 ?
                            <ReactBnbGallery
                                show={this.state.show_thumbnail}
                                activePhotoIndex={this.state.number_image}
                                photos={PHOTOS}
                                onClose={() => { this.setState({ show_thumbnail: false }) }}
                            /> : null
                        }
                        <div className="body-gallery-home" style={{ marginLeft: '3vw', marginRight: '3vw' }}>
                            <div className="gallery-home" id="gallery-home">
                                {images !== undefined ?
                                    images.map((item, no) => {
                                        if (item.images_type === 'Image') {
                                            return (
                                                <div key={`gallery${no}`} className="gallery-item-home">
                                                    <div className="content-home">
                                                        <img key={no}
                                                            src={GOBALS.URL_IMG + item.images_url}
                                                            alt=""
                                                            onClick={() => this.setState({ show_thumbnail: true, number_image: no })}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={`gallery${no}`} className="gallery-item-video-home">
                                                    <div className="content-home">
                                                        <Player
                                                            poster={`${GOBALS.URL_IMG}${item.images_profile_video}`}
                                                        >
                                                            <BigPlayButton position="center" />
                                                            <ControlBar autoHide={false}>
                                                                <PlayToggle />
                                                                <CurrentTimeDisplay disabled />
                                                                <TimeDivider disabled />
                                                            </ControlBar>
                                                            <source src={`${GOBALS.URL_IMG}${item.images_url}`} />
                                                        </Player>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }) : ''
                                }
                            </div>
                        </div>
                        <div className="container-flex-button-center">
                            <Link to="/gallery" >
                                <Button className="button-color-popular" variant="danger" size="lg">MORE GALLERY</Button>
                            </Link>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}
