import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'
import { Link } from "react-router-dom";

import AboutUsModel from '../../models/AboutUsModel';

const about_us_model = new AboutUsModel();

export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            about_us: []
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const about_us = await about_us_model.getAboutUsBy({ about_us_language: sessionStorage.getItem('language') });
        this.setState({
            about_us: about_us.data[0]
        })
    }
    render() {
        const { about_us } = this.state
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Founder"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Founder</div>
                </div>
                <Container>
                    <div className="w3-row w3-padding-64" >
                        <div className="w3-col m6 w3-padding-large">
                            {about_us.about_us_img !== undefined ?
                                <img src={GOBALS.URL_IMG + about_us.about_us_img}
                                    alt="aboutUs"
                                    className="w3-image image-about-brand"
                                />
                                : ''
                            }

                        </div>

                        <div className="w3-col m6 w3-padding-large">
                            <h1 className="w3-center" style={{ color: "#ad3235" }}>Founder</h1>
                            <div className="w3-large" style={{ lineHeight: "35px" }}>
                                <div dangerouslySetInnerHTML={{ __html: about_us.about_us_description }} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
