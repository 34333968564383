import React, { Component } from 'react'
import { Container, Row, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default class SendMessageLineSuccess extends Component {
    render() {
        return (
            <div>
                <Container style={{ marginTop: '150px', height: '600px' }}>
                    <Row className="box-send-success-line">
                        <h2 style={{ marginLeft: '20px', marginRight: '20px' }}>
                            Send Message To Line Success.
                        </h2>
                        <Link to="/home">
                            <Button style={{marginTop: '30px'}} size="lg">Go Home Page</Button>
                        </Link>
                    </Row>
                </Container>
            </div>
        )
    }
}
