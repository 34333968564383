import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import IntroPage from './views/IntroPage/view'
import Main from './views'
import AccessoriesRotate from './views/Accessories/rotate'
import GuitarRotate from './views/Guitar/rotate'
export default class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <IntroPage />
          </Route>
          <Route exact path="/guitar-rotate/:guitar_code" component={GuitarRotate} />
          <Route exact path="/accessories-rotate/:accessories_code" component={AccessoriesRotate} />
          <Route component={Main} />
        </Switch>
      </Router>
    )
  }
}
