import React, { Component } from 'react'
import { Container, Card, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Select from 'react-select';

import GuitarModel from '../../models/GuitarModel';
import GuitarTypeModel from '../../models/GuitarTypeModel';
import SeriesModel from '../../models/SeriesModel';
import FilterPriceModel from '../../models/FilterPriceModel';

const guitar_model = new GuitarModel();
const guitar_type_model = new GuitarTypeModel();
const series_model = new SeriesModel();
const filter_price_model = new FilterPriceModel();

export default class Popular extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guitar: [],
            series: [],
            filter_price: [],
            lowest_filter_price: 0,
            highest_filter_price: 0,
            guitar_types: [],
            filters_product: [{
                guitar_type_code: null,
                series_code: null,
                price_start: null,
                price_end: null
            }]
        };
    }

    componentDidMount() {
        AOS.init()
        let media_size = window.matchMedia("(max-width: 700px)")
        if (!media_size.matches) {
            document.getElementById("iconFilter").classList.remove('fa-angle-down');
        }
        this._fetchData()
    }


    async _fetchData() {
        var lowest_filter_price = Number.POSITIVE_INFINITY;
        var highest_filter_price = Number.NEGATIVE_INFINITY;
        var price_start;
        var price_end;

        const guitar_types = await guitar_type_model.getGuitarTypeBy();

        const filter_price = await filter_price_model.getFilterPriceBy({ show_home: 'Yes' });

        for (var i = filter_price.data.result.length - 1; i >= 0; i--) {
            price_start = filter_price.data.result[i].filter_price_start;
            price_end = filter_price.data.result[i].filter_price_end;
            if (price_start < lowest_filter_price) lowest_filter_price = price_start;
            if (price_end > highest_filter_price) highest_filter_price = price_end;
        }

        const series = await series_model.getSeriesBy({
            series_type: 'Guitar',
        });

        let filters_product = []
        for (const idx in guitar_types.data.result) {
            filters_product.push(
                {
                    guitar_type_code: null,
                    series_code: null,
                    price: null,
                }
            )
        }
        const guitar = await guitar_model.getGuitarBy({
            filters_product: filters_product,
            show_guitar_popular: 'Yes',
            guitar_detail_language: sessionStorage.getItem('language')
        })
        this.setState({
            lowest_filter_price: lowest_filter_price,
            highest_filter_price: highest_filter_price,
            guitar_types: guitar_types.data.result,
            guitar: guitar.data.result,
            series: series.data.result,
            filter_price: filter_price.data.result,
            filters_product: filters_product
        })
    }

    async _filterSeriesChange(e) {
        let filters_product = this.state.filters_product
        if (e.value === null) {
            if (filters_product[e.idx].price === null) {
                filters_product[e.idx].guitar_type_code = null
            }
            filters_product[e.idx].series_code = null
        } else {
            filters_product[e.idx].guitar_type_code = e.guitar_type_code
            filters_product[e.idx].series_code = e.value
        }

        const guitar = await guitar_model.getGuitarBy({
            filters_product: filters_product,
            show_guitar_popular: 'Yes',
            guitar_detail_language: sessionStorage.getItem('language')
        })

        this.setState({
            filters_product: filters_product,
            guitar: guitar.data.result,
        })
    };

    async _filterPriceChange(e) {
        let filters_product = this.state.filters_product
        if (e.value === null) {
            if (filters_product[e.idx].series_code === null) {
                filters_product[e.idx].guitar_type_code = null
            }
            filters_product[e.idx].price = null
        } else {
            filters_product[e.idx].guitar_type_code = e.guitar_type_code
            filters_product[e.idx].price = e.value
        }

        const guitar = await guitar_model.getGuitarBy({
            filters_product: filters_product,
            show_guitar_popular: 'Yes',
            guitar_detail_language: sessionStorage.getItem('language')
        })

        this.setState({
            filters_product: filters_product,
            guitar: guitar.data.result,
        })
    };

    _openFilter() {
        let media_size = window.matchMedia("(max-width: 700px)")
        let filter = document.getElementsByClassName('item-filter')
        if (media_size.matches) {
            if (filter[0].style.display === 'none' || filter[0].style.display === "") {
                filter[0].style.display = 'block';
                document.getElementById("iconFilter").classList.remove('fa-angle-down');
                document.getElementById("iconFilter").classList.add('fa-angle-up');
            } else {
                filter[0].style.display = 'none';
                document.getElementById("iconFilter").classList.remove('fa-angle-up');
                document.getElementById("iconFilter").classList.add('fa-angle-down');
            }
        } else {
            filter[0].style.display = 'block';
            document.getElementById("iconFilter").classList.remove('fa-angle-up');
            document.getElementById("iconFilter").classList.remove('fa-angle-down');
        }
    }

    render() {
        const { guitar, guitar_types, series, filter_price } = this.state
        const selects = guitar_types.map((item, no) => {
            let array_min = []
            array_min.push('min')
            array_min.push(this.state.lowest_filter_price)
            var object_filter_price = [{
                value: array_min,
                label: this.state.lowest_filter_price + ' < ',
                idx: no,
                guitar_type_code: item.guitar_type_code,
            }]
            filter_price.forEach((item_price) => {
                let array = []
                array.push(item_price.filter_price_start)
                array.push(item_price.filter_price_end)
                object_filter_price.push({
                    value: array,
                    label: item_price.filter_price_start + ' - ' + item_price.filter_price_end,
                    idx: no,
                    guitar_type_code: item.guitar_type_code,
                })
            })
            let array_max = []
            array_max.push('max')
            array_max.push(this.state.highest_filter_price)
            object_filter_price.push({
                value: array_max,
                label: ' > ' + this.state.highest_filter_price,
                idx: no,
                guitar_type_code: item.guitar_type_code,
            })
            return (
                <div key={no}>
                    <p className="list-filter">{item.guitar_type_name.toUpperCase()}</p>
                    <h5>Series</h5>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={(val) => {
                            this._filterSeriesChange(
                                val !== null ?
                                    {
                                        value: val.value,
                                        idx: no,
                                        guitar_type_code: val.guitar_type_code,
                                    }
                                    :
                                    {
                                        value: null,
                                        idx: no,
                                        guitar_type_code: null,
                                    }
                            )
                        }}
                        options={series.map((item_series) => {
                            return {
                                value: item_series.series_code,
                                label: item_series.series_name,
                                idx: no,
                                guitar_type_code: item.guitar_type_code,
                            }
                        })}
                    />
                    <h5 style={{ marginTop: '10px' }}>Price</h5>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        onChange={(val) => {
                            this._filterPriceChange(
                                val !== null ?
                                    {
                                        value: val.value,
                                        idx: no,
                                        guitar_type_code: val.guitar_type_code,
                                    }
                                    :
                                    {
                                        value: null,
                                        idx: no,
                                        guitar_type_code: null,
                                    }
                            )
                        }}
                        options={object_filter_price}
                    />
                    <br />
                </div>
            )
        })
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Home"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Popular</div>
                </div>

                <Container fluid>
                    <Row>
                        <Col className="title-header">
                            POPULAR
                        </Col>
                    </Row>
                    <div className="w3-row" >
                        <div className="w3-col m3 filter-margin">
                            <div className="title-filter" style={{ marginBottom: '15px' }} onClick={() => this._openFilter()}>
                                FILTER
                                <i
                                    id="iconFilter"
                                    style={{ marginLeft: '15px', fontSize: '1.2rem' }}
                                    className="fa fa-angle-down"
                                    aria-hidden="true"
                                    media="screen and (max-width: 500px)">
                                </i>
                            </div>
                            <div className="item-filter">
                                <Link to={`/popular/guitar`} style={{ width: '100%' }}>
                                    <p className="list-filter" style={{ color: '#ad3235' }}><i className="fas fa-angle-right"> </i> GUITAR</p>
                                </Link>
                                <Link to={`/popular/accessories`} style={{ width: '100%' }}>
                                    <p className="list-filter" style={{ color: '#000', marginBottom: '25px' }}><i className="fas fa-angle-right"> </i> ACCESSORIES</p>
                                </Link>
                                <hr />
                                {selects}
                            </div>
                        </div>

                        <div className="w3-col m9">
                            <div className="container-flex-product">
                                {guitar.map((item, no) => (
                                    <Col className="size-block-product" data-aos='fade-up' data-aos-duration="1000" key={no} lg="4">
                                        <a href={`https://www.fonzoguitar.com/guitar-type/${item.guitar_type_code}/${item.guitar_code}`} style={{ width: '100%' }}>
                                            <Card
                                                lg="auto"
                                                className="links card-body-product"
                                            >
                                                {item.guitar_img_url !== null ?
                                                    < Card.Img variant="top"
                                                        className="img-size-product"
                                                        src={GOBALS.URL_IMG + item.guitar_img_url}
                                                        style={{ marginTop: '5px' }}
                                                    />
                                                    :
                                                    < Card.Img variant="top"
                                                        className="img-size-product-default"
                                                        style={{ objectFit: 'cover' }}
                                                        src={require('../../assets/img/default-image.jpg')}
                                                        style={{ marginTop: '5px' }}
                                                    />
                                                }
                                                <Card.Body style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                    <Card.Title style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: '#000' }}>
                                                        {item.guitar_detail_text}
                                                    </Card.Title>
                                                    <Card.Text style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: 'red' }}>
                                                        ฿{item.guitar_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Col>
                                ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </div >
        )
    }
}
