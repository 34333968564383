import GOBALS from '../GOBALS';

export default class GuitarImgModel{
    async getGuitarImgBy(data){
        return fetch(GOBALS.URL + 'guitar-img/getGuitarImgBy',{
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        }).catch((error) => {
            console.error(error);
        });
    }
}