import GOBALS from '../GOBALS';

export default class AccessoriesTypeModel {
    async getAccessoriesTypeBy(data) {
        return fetch(GOBALS.URL + 'accessoriesType/getAccessoriesTypeBy', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            }).catch((error) => {
                console.error(error);
            });
    }

    async getAccessoriesTypeByCode(data) {
        return fetch(GOBALS.URL + 'accessoriesType/getAccessoriesTypeByCode', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            }).catch((error) => {
                console.error(error);
            });
    }
}