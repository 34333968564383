import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '../../assets/css/body.css'
import GoogleMapReact from 'google-map-react';
import { Link } from "react-router-dom";
import swal from 'sweetalert2';
import Banner from '../Banner'
import ReCAPTCHA from "react-google-recaptcha";

import ContactModel from '../../models/ContactModel';
import SettingModel from '../../models/SettingModel';

const contact_model = new ContactModel();
const setting_model = new SettingModel();
export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            message: '',
            tel: '',
            setting: [],
            check_re_captcha: true
        };
    }

    componentDidMount() {
        this._fetchData(sessionStorage.getItem('language'));
    }

    async _fetchData(data) {
        const setting = await setting_model.getSettingBy({ address_language: data });
        this.setState({
            setting: setting.data[0]
        })
    }

    async _handleSubmit() {
        if (this._checkSubmit()) {
            const contact = await contact_model.sendMail({
                name: this.state.name,
                email: this.state.email,
                tel: this.state.tel,
                message: this.state.message
            })
            if (contact.sending_result === true) {
                swal.fire({
                    title: "Send Email success!",
                    icon: "success",
                });
            } else {
                swal.fire({
                    title: "Not Send Email!",
                    icon: "warning",
                });
            }
        }
    }

    _checkSubmit() {
        if (this.state.name === '') {
            swal.fire({
                title: "Warning!",
                text: "Please Check Name.",
                icon: "warning"
            });
            return false
        } else if (this.state.email === '') {
            swal.fire({
                title: "Warning!",
                text: "Please Check Email.",
                icon: "warning"
            });
            return false
        } else if (this.state.message === '') {
            swal.fire({
                title: "Warning!",
                text: "Please Check Message.",
                icon: "warning"
            });
            return false
        } else {
            return true
        }
    }

    _handleApiLoaded = (map, maps) => {
        var myLatLng = {
            lat: parseFloat(this.state.setting.setting_lat),
            lng: parseFloat(this.state.setting.setting_lng)
        };
        new maps.Marker({
            position: myLatLng,
            map,
            title: this.state.setting.address_detail
        });
    }
    static defaultProps = {
        zoom: 16
    };

    _onCheckReCaptcha = value => {
        this.setState({
            check_re_captcha: false
        })
      };
    render() {
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Home"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Contact </div>
                </div>
                <Container>
                    <Row className="contact-box">
                        <Col sm={8}>
                            <div className="contact-map">
                                {this.state.setting.setting_lat !== undefined ?
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyBPYt_mZGd-2iotzhpiZKw1_GpZ6H9w3vs" }}
                                        defaultCenter={{
                                            lat: parseFloat(this.state.setting.setting_lat),
                                            lng: parseFloat(this.state.setting.setting_lng)
                                        }}
                                        defaultZoom={this.props.zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => this._handleApiLoaded(map, maps)}
                                    >
                                    </GoogleMapReact>
                                    :
                                    ''
                                }
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '1.8rem' }}>
                                วันและเวลาทำการ
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.setting.setting_business_hours }} />
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <h3 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>Contact</h3>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Name"
                                    style={{ marginTop: '10px' }}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name} />

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Email"
                                    style={{ marginTop: '10px' }}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    value={this.state.email} />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Tel Number"
                                    style={{ marginTop: '10px' }}
                                    onChange={(e) => this.setState({ tel: e.target.value })}
                                    value={this.state.tel} />
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Message"
                                    style={{ marginTop: '10px' }}
                                    rows="8"
                                    onChange={(e) => this.setState({ message: e.target.value })}
                                    value={this.state.message}
                                >
                                </textarea>
                                <ReCAPTCHA
                                    sitekey="6Leyxt4ZAAAAAAQh8tJJNAIMbu7QIPYdF6KcBy4W"
                                    onChange={this._onCheckReCaptcha}
                                />
                                <button
                                    className="btn btn-info"
                                    style={{ marginTop: '10px', width: '100%', fontWeight: 'bold', marginBottom: '30px' }}
                                    onClick={this._handleSubmit.bind(this)}
                                    disabled={this.state.check_re_captcha}
                                >
                                    Send
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
