import React, { Component } from 'react'
import { Container, Table, Col, Card } from 'react-bootstrap';
import Rotation from 'react-rotation'
import { Link } from "react-router-dom";
import '../../assets/css/body.css'
import '../../assets/css/image-slide-show.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner';
import ReactPlayer from 'react-player/youtube'
import Slider from "react-slick";
import MessengerSendToMessenger from 'react-messenger-send-to-messenger';
import 'react-bnb-gallery/dist/style.css'
import ReactBnbGallery from 'react-bnb-gallery';

import AccessoriesDetailModel from '../../models/AccessoriesDetailModel';
import AccessoriesImgModel from '../../models/AccessoriesImgModel';
import AccessoriesImgRotateModel from '../../models/AccessoriesImgRotateModel';
import AccessoriesModel from '../../models/AccessoriesModel';

const accessories_detail_model = new AccessoriesDetailModel();
const accessories_img_model = new AccessoriesImgModel();
const accessories_img_rotate_model = new AccessoriesImgRotateModel();
const accessories_model = new AccessoriesModel();

export default class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accessories_detail_title: [],
            accessories_detail: [],
            accessories_img: [],
            accessories_img_rotate: [],
            accessories_related: [],
            accessories_code: '',
            accessories_type_code: '',
            slide_index: 1,
            idx: 1,
            show_thumbnail: false,
            number_image: 0
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async componentWillReceiveProps(nextProps) {
        const accessories_code_next = nextProps.match.params.accessories_code;
        const accessories_type_code_next = nextProps.match.params.accessories_type_code;
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this._fetchData(accessories_code_next, accessories_type_code_next)
        }
    }
    async _fetchData(accessories_code_next = '', accessories_type_code_next = '') {
        let accessories_code
        let accessories_type_code
        if (accessories_code_next === '' && accessories_type_code_next === '') {
            accessories_code = this.props.match.params.accessories_code;
            accessories_type_code = this.props.match.params.accessories_type_code;
        } else {
            accessories_code = accessories_code_next;
            accessories_type_code = accessories_type_code_next;
            window.scrollTo(0, 0);
        }

        let image_rotate = []
        let accessories_img_rotate_get = [];

        const accessories_detail_title = await accessories_detail_model.getAccessoriesDetailBy({
            accessories_detail_language: sessionStorage.getItem('language'),
            accessories_code: accessories_code
        })
        var accessories_related
        if (accessories_detail_title.query_result === true) {
            let accessories_detail_name = accessories_detail_title.data[0].accessories_detail_name.split(" ")
            let j = 0
            let text = ""
            for (j = 0; j < accessories_detail_name.length; j++) {
                if (accessories_detail_name[j] !== "") {
                    text = text + accessories_detail_name[j]
                    if (j !== (accessories_detail_name.length - 1)) {
                        text = text + "|"
                    }
                }
            }
            accessories_related = await accessories_model.getAccessoriesBy({
                accessories_detail_text: accessories_detail_title.data[0].accessories_detail_name,
                text: text,
                accessories_detail_language: sessionStorage.getItem('language')
            });

        }


        const accessories_img = await accessories_img_model.getAccessoriesImgBy({ accessories_code: accessories_code })

        const accessories_img_rotate = await accessories_img_rotate_model.getAccessoriesImgRotateBy({ accessories_code: accessories_code })

        const accessories_detail = await accessories_detail_model.getAccessoriesDetailBy({
            accessories_detail_language: sessionStorage.getItem('language'),
            accessories_code: accessories_code,
            show_specifications_overview: true
        })

        let i
        if (accessories_img_rotate.data.length > 0) {
            accessories_img_rotate_get = accessories_img_rotate.data[0];
            for (i = 0; i < 16; i++) {
                image_rotate.push({
                    accessories_img_rotate: accessories_img_rotate_get[`accessories_img_rotate_${i}`],
                })
            }
        }

        this.setState({
            accessories_detail_title: accessories_detail_title.data.length > 0 ? accessories_detail_title.data[0] : [],
            accessories_detail: accessories_detail.data,
            accessories_img: accessories_img.data.result,
            accessories_img_rotate: image_rotate,
            accessories_related: accessories_related !== undefined ? accessories_related.data.result : [],
            accessories_code: accessories_code,
            accessories_type_code: accessories_type_code,
        })

        this._showSlide(1)
    }

    _currentSlide(number) {
        this._showSlide(number);
        this.setState({
            slide_index: number
        })
    }

    _showSlide(number) {
        if ((this.state.accessories_img_rotate !== undefined && this.state.accessories_img_rotate.length > 0) || (this.state.accessories_img !== undefined && this.state.accessories_img.length > 0)) {
            var i;
            var slide_index = number
            var slides = document.getElementsByClassName("SlideImg");
            var dots = document.getElementsByClassName("show");
            if (number > slides.length) {
                slide_index = 1
            }
            if (number < 1) {
                slide_index = slides.length
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[slide_index - 1].style.display = "block";
            dots[slide_index - 1].className += " active";
        }
    }

    _doubleClickRotate() {
        window.open(`/accessories-rotate/${this.state.guitar_code}`);
    }
    render() {
        const { accessories_detail, accessories_img, accessories_img_rotate, accessories_related, accessories_detail_title } = this.state
        var setting_slide = {
            className: "slider variable-width container-flex-popular",
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const PHOTOS = this.state.accessories_img.map((item, no) => {
            return {
                photo: GOBALS.URL_IMG + item.accessories_img_url,
                number: no,
                thumbnail: GOBALS.URL_IMG + item.accessories_img_url,
            }
        })
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Accessories"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <Link to="/accessories-type">
                        <div>Accessories Type</div>
                    </Link>
                    <div>/</div>
                    <Link to={`/accessories-type/${this.state.accessories_type_code}`}>
                        <div>Accessories</div>
                    </Link>
                    <div>/</div>
                    <div>Accessories Detail</div>
                </div>
                {this.state.accessories_img.length !== 0 ?
                    <ReactBnbGallery
                        show={this.state.show_thumbnail}
                        activePhotoIndex={this.state.number_image}
                        photos={PHOTOS}
                        onClose={() => { this.setState({ show_thumbnail: false }) }}
                    /> : null
                }
                <Container style={{ marginTop: '40px' }}>
                    <div className="w3-row style-container">
                        <div className="w3-col m6 padding-detail-1">
                            <div className="container-show " id="gallery">
                                {accessories_img_rotate !== undefined && accessories_img_rotate.length > 0 ?
                                    <div className="SlideImg" onDoubleClick={() => this._doubleClickRotate()}>
                                        <Rotation
                                            cycle={true}
                                            className="big-image-show"
                                        >
                                            {accessories_img_rotate.map((item, no) => (
                                                <img
                                                    key={`${no}`}
                                                    src={GOBALS.URL_IMG + item.accessories_img_rotate}
                                                />
                                            ))}
                                        </Rotation>
                                        <div className="text-show-click-in-rotate">
                                            <i className="fas fa-sync-alt icon-into-image"></i>
                                            <i className="fas fa-expand-alt icon-into-image"></i>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {accessories_img !== undefined ?
                                    accessories_img.map((item, no) => (
                                        <div className="SlideImg content" key={`${no}`} onClick={() => this.setState({ show_thumbnail: true, number_image: no })}>
                                            <img
                                                className="big-image-show"
                                                src={GOBALS.URL_IMG + item.accessories_img_url}
                                            />
                                            <div className="text-show-click-in-rotate">
                                                <i className="fas fa-expand-alt icon-into-image"></i>
                                            </div>
                                        </div>
                                    ))
                                    : ''
                                }
                                <div className="rows">
                                    {accessories_img_rotate[0] !== undefined && accessories_img_rotate.length > 0 ?
                                        <div className="image-360-container">
                                            <img
                                                className="show cursor column "
                                                onClick={() => this._currentSlide(1)} alt="show"
                                                src={GOBALS.URL_IMG + accessories_img_rotate[0].guitar_img_rotate}
                                            />
                                            <img
                                                className="icon-360"
                                                src={require('../../assets/img/icon-360.png')}
                                            />
                                        </div>
                                        : ''
                                    }
                                    {accessories_img !== undefined ?
                                        accessories_img.map((item, no) => {
                                            if (accessories_img_rotate !== undefined && accessories_img_rotate.length > 0) {
                                                var i = 2 + no
                                            } else {
                                                var i = 1 + no
                                            }
                                            return (
                                                <img
                                                    className="show cursor column"
                                                    key={i}
                                                    onClick={() => { this._currentSlide(i) }} alt="show"
                                                    src={GOBALS.URL_IMG + item.accessories_img_url}
                                                />
                                            )
                                        })
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="w3-col m6 padding-detail-2">
                            <p className="font-style-name">{accessories_detail_title.accessories_detail_name}</p>
                            <p className="font-style-price">฿{accessories_detail_title.accessories_price !== undefined ? accessories_detail_title.accessories_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ''}</p>
                            <div className="send-order-container">
                                <label className="label-order">สั่งซื้อสินค้าผ่าน: </label>
                                <div className="send-message-container">
                                    <MessengerSendToMessenger
                                        pageId="101215031662988"
                                        version="8.0"
                                        appId="329182885045463"
                                        className="fb-order"
                                        language="th_TH"
                                        ctaText="GET_THIS_IN_MESSENGER"
                                        dataRef={this.state.accessories_code + '/' + sessionStorage.getItem('language') + '/Accessories'}
                                    />
                                    <a
                                        href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655049958&redirect_uri=${GOBALS.URL}webhook/line&state=revelsoftline-Accessories-${this.state.accessories_code}-${sessionStorage.getItem('language')}&bot_prompt=aggressive&scope=openid&nonce=09876xyz`}
                                        target="_blank"
                                    >
                                        <img src={require('../../assets/img/line-botton.png')} className="line-btn" />
                                    </a>
                                </div>
                            </div>
                            <div className="font-style-description">
                                {accessories_detail_title.accessories_detail_description}
                            </div>
                            <div>
                                {accessories_detail_title.accessories_vdo !== undefined && accessories_detail_title.accessories_vdo !== '' ?
                                    <ReactPlayer controls={true} url={accessories_detail_title.accessories_vdo} className="style-video" />
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    <Col className="title-overview">
                        Specifications Overview
                    </Col>
                    <center>
                        <Table borderless className="style-table">
                            <tbody>
                                <tr>
                                    <td className="td-title" width="320px">SERIES: </td>
                                    <td>{accessories_detail_title.series_name}</td>
                                </tr>
                                {accessories_detail.map((item, no) => (
                                    item.accessories_detail_title !== '' && item.accessories_detail_text !== '' ?
                                        <tr key={no}>
                                            <td className="td-title" width="320px">{item.accessories_detail_title.toUpperCase()}: </td>
                                            <td>{item.accessories_detail_text}</td>
                                        </tr>
                                        : null
                                ))}
                            </tbody>
                        </Table>
                    </center>
                    <Col className="title-overview">
                        Related Products
                    </Col>
                    <Slider {...setting_slide}>
                        {accessories_related.map((item, no) => (
                            <div className="product-list" key={no}>
                                <a href={`https://www.fonzoguitar.com/accessories-type/${item.accessories_type_code}/${item.accessories_code}`} style={{ width: '100%' }}>
                                    <Card
                                        className="links card-body card-body-size"
                                    >
                                        {item.accessories_img_url !== null ?
                                            < Card.Img variant="top"
                                                className="img-size-popular"
                                                src={GOBALS.URL_IMG + item.accessories_img_url}
                                            />
                                            :
                                            < Card.Img variant="top"
                                                height="250px"
                                                width="180px"
                                                style={{ objectFit: 'cover' }}
                                                src={require('../../assets/img/default-image.jpg')}
                                            />
                                        }
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: '#000' }}>
                                                {item.accessories_detail_text}
                                            </Card.Title>
                                            <Card.Text style={{ fontWeight: 'bold', fontFamily: 'kanit', fontSize: '1.2rem', color: 'red' }}>
                                                ฿{item.accessories_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </Container>
            </div>
        )
    }
}
