import React, { Component } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'

import AlbumModel from '../../models/AlbumModel';

const album_model = new AlbumModel();
export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            albums: []
        };
    }
    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const albums = await album_model.getAlbumBy();
        this.setState({
            albums: albums.data.result
        })
    }
    render() {
        const { albums } = this.state
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Gallery"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Gallery </div>
                </div>
                <Container>
                    <Row>
                        <Col className="title">
                            Gallery
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '90px'}}>
                        {albums.map((item, no) => (
                            <Col key={no} lg={'auto'} style={{marginBottom: '15px'}}>
                                <Link exact to={`/gallery/detail/${item.album_code}`} style={{ width: '100%', color: '#000' }}>
                                    <Card
                                        className="links card-body size-block"
                                    >
                                        {item.images_url !== null ?
                                            <Card.Img
                                                variant="top"
                                                src={GOBALS.URL_IMG + item.images_url}
                                                style={{ borderRadius: "5px", objectFit: "cover" }}
                                            />
                                            :
                                            <Card.Img
                                                variant="top"
                                                src={require('../../assets/img/default-image.jpg')}
                                                style={{ borderRadius: "5px", objectFit: "cover" }}
                                            />
                                        }

                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                                {item.album_name}
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        )
    }
}
