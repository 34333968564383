import React, { Component } from 'react'
import Rotation from 'react-rotation'
import GOBALS from '../../GOBALS';
import '../../assets/css/body.css'

import GuitarImgRotateModel from '../../models/GuitarImgRotateModel';

const guitar_img_rotate_model = new GuitarImgRotateModel();

export default class Rotate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            guitar_related: [],
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        let guitar_code = this.props.match.params.guitar_code;
        let image_rotate = []
        let i

        const guitar_img_rotate = await guitar_img_rotate_model.getGuitarImgRotateBy({ guitar_code: guitar_code })

        if (guitar_img_rotate.data.length > 0) {
            let guitar_img_rotate_get = guitar_img_rotate.data[0];
            for (i = 0; i < 16; i++) {
                image_rotate.push({
                    guitar_img_rotate: guitar_img_rotate_get[`guitar_img_rotate_${i}`],
                })
            }
        }
        this.setState({
            guitar_img_rotate: image_rotate,
        })
    }
    _doubleClickRotate() {
        var elem = document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
    render() {
        const { guitar_img_rotate } = this.state
        return (
            <div onDoubleClick={() => this._doubleClickRotate()} style={{cursor: 'pointer'}}>
                {guitar_img_rotate !== undefined && guitar_img_rotate.length > 0 ?
                    <Rotation cycle={true} >
                        {guitar_img_rotate.map((item, no) => (
                            <img
                                key={`${no}`}
                                src={GOBALS.URL_IMG + item.guitar_img_rotate}
                                className="big-image-rotate-show"
                            />
                        ))}
                    </Rotation>
                    : ''
                }
            </div>
        )
    }
}
